import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

const Root = () => {
  const [msg, setMsg] = useState("")
  useEffect(() => {
    const fetchAndSetMsg = async () => {
      const resp = await fetch('/api/message');
      const respBody = await resp.text();
      setMsg(respBody);
    };
    fetchAndSetMsg();
  }, []);

  return (
    <div>
      Listener Page
      <div>
        { msg }
      </div>
      <Link to="internal">internal</Link>
    </div>
  );
};

export default Root;
