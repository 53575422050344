import { useState, useEffect } from 'react';

const Internal = () => {
  const [email, setEmail] = useState("")
  useEffect(() => {
    const fetchAndSetMsg = async () => {
      const resp = await fetch('/api/checkAuth');
      const respBody = await resp.text();
      if (resp.status !== 200) {
        console.error(respBody)
      } else {
        setEmail(respBody);
      }
    };
    fetchAndSetMsg();
  }, []);

  return (
    <div>
      <div>
        { email }
      </div>
    </div>
  );
};

export default Internal;
